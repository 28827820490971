<template>
  <div class="main-container">
    <div class="block">
      <div class="block__img block__img-register">
        <div class="container">
          <div class="block__info">
            <div class="block__title">Регистрация</div>
            <div class="block__description">
              Заполните форму ниже и прикрепите необходимые документы. Для продолжения работы с системой Вам необходимо ознакомиться и подтвердить согласие с правилами работы торговой площадки и политикой обработки персональных данных.
            </div>
          </div>
        </div>
      </div>
      <div class="register">
        <div class="container">
          <VLink @click="$router.push({ name: 'showcase' })" class="to_main mt-60 mb-20">На главную</VLink>

          <h2 class="register__title mb-40">
            Аккредитация - обязательное условие для участия в торговых процедурах.
          </h2>

          <p class="mb-60">В связи с открытием новой Тендерной площадки, поступает большое количество заявок на аккредитацию, поэтому стандартное время аккредитации может увеличиться. Заранее приносим Вам извинения за дополнительное время ожидания.
            Для связи по вопросам аккредитации эл.почтовый ящик
            <b><a href="mailto:accreditation@sitno.ru">accreditation@sitno.ru</a></b>
            , спасибо что Вы с нами.
          </p>

          <StepList class="mb-20" :steps="registrationSteps" />

          <form @submit.prevent="onSubmit" class="form" enctype="multipart/form-data">
            <div class="form__inner">
              <div class="form__flex form__flex-start mt-60">
                <div class="form__left">

                  <VInput
                    name="login"
                    id="login"
                    v-model="formData.login"
                    :errors="v$.login.$errors"
                    :server-errors="serverErrors.value?.login"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black']"
                    placeholder="Логин (мин. 3 символа)*"
                  />

                  <VInput
                    name="name"
                    id="name"
                    validator-name="onlyText"
                    v-model.trim="formData.name"
                    :errors="v$.name.$errors"
                    :server-errors="serverErrors.value?.name"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black']"
                    placeholder="Контактное лицо ФИО*"
                  />

                  <VInput
                    name="password"
                    id="password"
                    v-model.trim="formData.password"
                    :errors="v$.password.$errors"
                    type="password"
                    :server-errors="serverErrors.value?.password"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black']"
                    placeholder="Пароль (мин. 8 символов)"
                  />

                  <VInput
                    name="confirmPassword"
                    id="confirmPassword"
                    v-model.trim="formData.confirmPassword"
                    :errors="v$.confirmPassword.$errors"
                    type="password"
                    :server-errors="serverErrors.value?.confirmPassword"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black']"
                    placeholder="Подтверждение пароля*"
                  />

                  <VInput
                    name="email"
                    id="email"
                    v-model="formData.email"
                    :errors="v$.email.$errors"
                    :server-errors="serverErrors.value?.email"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black']"
                    placeholder="Адрес электронной почты*"
                  />

                  <VPhone
                    type="phone"
                    id="phone"
                    name="phone"
                    placeholder="Номер телефона"
                    mask-type
                    :class-input="['input input-long input-placeholder-black']"
                    v-model="formData.phone"
                    :errors="v$.phone.$errors"
                    :server-errors="serverErrors.value?.phone"
                    @blur="validateField"
                    @input="validateField"
                  />

                  <VSelect
                    v-model="formData.partner_type_id"
                    name="partner_type_id"
                    id="partner_type_id"
                    :errors="v$.partner_type_id.$errors"
                    :server-errors="serverErrors.value?.partner_type_id"
                    :options="listTypeContrAgent"
                    @blur="validateField"
                    @input="validateField"
                    classes="select-border-bottom"
                    placeholder="Тип контрагента*"
                  />

                  <VSelect
                    v-model="formData.organization_type_id"
                    name="organization_type_id"
                    id="organization_type_id"
                    :errors="v$.organization_type_id.$errors"
                    :server-errors="serverErrors.value?.organization_type_id"
                    :options="listTypeOrganization"
                    classes="select-border-bottom"
                    class="input-long"
                    placeholder="Тип организации*"
                  />

                  <VInput
                    name="kpp"
                    id="kpp"
                    type="number"
                    validator-name="onlyNumber"
                    v-model="formData.kpp"
                    :errors="v$.kpp.$errors"
                    :server-errors="serverErrors.value?.kpp"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black input-border-bottom', disabledKpp ? 'disabled' : '']"
                    :class-label="disabledKpp ? 'disabled' : ''"
                    :disabled="disabledKpp"
                    placeholder="КПП"
                  />

                </div>
                <div class="form__right">

                  <VInput
                    name="inn"
                    id="inn"
                    type="number"
                    validator-name="onlyNumber"
                    v-model="formData.inn"
                    :errors="v$.inn.$errors"
                    :server-errors="serverErrors.value?.inn"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="ИНН*"
                  />

                  <VInput
                    name="organization_name"
                    id="organization_name"
                    v-model="formData.organization_name"
                    :server-errors="serverErrors.value?.organization_name"
                    :errors="v$.organization_name.$errors"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="Сокращенное наименование организации*"
                  />

                  <VInput
                    name="organization_legal_address"
                    id="organization_legal_address"
                    v-model="formData.organization_legal_address"
                    :errors="v$.organization_legal_address.$errors"
                    :server-errors="serverErrors.value?.organization_legal_address"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="Юр. адрес организации*"
                  />

                  <VInput
                    name="organization_actual_address"
                    id="organization_actual_address"
                    v-model="formData.organization_actual_address"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="Почтовый адрес организации"
                  />

                  <VInput
                    name="organization_index"
                    id="organization_index"
                    type="number"
                    validator-name="onlyNumber"
                    :max-length="6"
                    v-model="formData.organization_index"
                    :errors="v$.organization_index.$errors"
                    :server-errors="serverErrors.value?.organization_index"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="Почтовый индекс организации*"
                  />

                  <VInput
                    name="msrn"
                    id="msrn"
                    type="number"
                    validator-name="onlyNumber"
                    v-model.trim="formData.msrn"
                    :class-input="['input input-long input-placeholder-black input-border-bottom', disabledField ? 'disabled' : '']"
                    :class-label="disabledField ? 'disabled' : ''"
                    placeholder="ОГРН*"
                    :disabled="disabledField"
                    :errors="v$.msrn.$errors"
                    :server-errors="serverErrors.value?.msrn"
                    @blur="validateField"
                    @input="validateField"
                  />

                  <VInput
                    name="arctea"
                    id="arctea"
                    type="text"
                    validator-name="onlyNumber"
                    v-model="formData.arctea"
                    :errors="v$.arctea.$errors"
                    :server-errors="serverErrors.value?.arctea"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="ОКВЭД*"
                  />

                  <VInput
                    name="director"
                    id="director"
                    validator-name="onlyText"
                    v-model="formData.director"
                    :errors="v$.director.$errors"
                    :server-errors="serverErrors.value?.director"
                    @blur="validateField"
                    @input="validateField"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="Руководитель организации*"
                  />

                  <VInput
                    name="trusted_person"
                    id="trusted_person"
                    validator-name="onlyText"
                    v-model="formData.trusted_person"
                    :server-errors="serverErrors.value?.trusted_person"
                    :class-input="['input input-long input-placeholder-black input-border-bottom']"
                    placeholder="Действующий по доверенности"
                  />

                </div>
              </div>

              <VCheckbox
                id="familiar_with_rules"
                name="familiar_with_rules"
                class-label="label"
                class-form="form-checkbox mb-20"
                :value="formData.familiar_with_rules"
                v-model="formData.familiar_with_rules"
                :errors="v$.familiar_with_rules.$errors"
                :server-errors="serverErrors.value?.familiar_with_rules"
                @blur="validateField"
                @input="validateField"
              >
                <template #default="slotProps">
                  <label :for="slotProps.for" :class="slotProps.class">Я ознакомился с условиями&nbsp;
                    <a class="btn btn-link" type="button">«С правилами работы торговой площадки»</a>
                  </label>
                </template>
              </VCheckbox>

              <VCheckbox
                id="familiar_with_policy"
                name="familiar_with_policy"
                class-label="label"
                class-form="form-checkbox mb-20"
                :value="formData.familiar_with_policy"
                v-model="formData.familiar_with_policy"
                :errors="v$.familiar_with_policy.$errors"
                :server-errors="serverErrors.value?.familiar_with_policy"
                @blur="validateField"
                @input="validateField"
              >
                <template #default="slotProps">
                  <label :for="slotProps.for" :class="slotProps.class">Я ознакомился с условиями&nbsp;
                    <a href="https://sitno.ru/politika-obrabotki-personalnyh-dannyh" target="_blank" class="btn btn-link" type="button">«Политикой обработки персональных данных»</a>
                  </label>
                </template>
              </VCheckbox>

            </div>

            <div class="mb-40">
              <VCaptcha @verify="verifyCaptcha"/>
            </div>

            <div class="form__buttons form__buttons-registration">
              <VButtonLoading
                :is-disabled="isSend || !isCaptcha"
                :is-errors="v$.$error"
                color="green"
                class="btn-medium"
              >
                Зарегистрироваться
              </VButtonLoading>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <TheFooter />
</template>

<script>
import { onMounted, ref } from 'vue'
import StepList from '@/components/list/StepList/StepList'
import TheFooter from '@/components/TheFooter'
import VInput from '@/components/ui/form/VInput'
import VPhone from '@/components/ui/form/VMask'
import VSelect from '@/components/ui/form/VSelect'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import VLink from '@/components/ui/buttons/VLink'
import { useRegisterForm } from '@/use/Form/Auth/register/useRegisterForm'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'
import VCaptcha from '@/components/ui/catpcha/VCaptcha'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { ORGANIZATIONS, PARTNERS } from '@/utils/manual/manualList'
import { REGISTRATION_STEPS } from '@/utils/text/registrationSteps'

export default {
  setup () {
    const listTypeContrAgent = ref([])
    const listTypeOrganization = ref([])

    onMounted(async () => {
      listTypeOrganization.value = await manualMemoization(false, ORGANIZATIONS)
      listTypeContrAgent.value = await manualMemoization(false, PARTNERS)
    })

    return {
      listTypeContrAgent,
      listTypeOrganization,
      registrationSteps: REGISTRATION_STEPS,
      ...useRegisterForm()
    }
  },
  components: {
    StepList,
    VCaptcha,
    VButtonLoading,
    VSelect,
    VPhone,
    VInput,
    TheFooter,
    VCheckbox,
    VLink
  }
}
</script>
