<template>
  <ul class="step-list">
    <li
      v-for="(step, i) in steps"
      :key="step.id"
      class="step-list__item"
      :class="{ 'step-list__item--border': (i !== 0) && step.title }"
    >
      <StepCard
        :title="step.title"
        :number="i + 1"
        :description="step.description"
        :has-icon="i !== steps.length - 1"
        has-title
      />
    </li>
  </ul>
</template>

<script>
import StepCard from '@/components/list/StepList/StepCard'
export default {
  name: 'StepList',
  components: { StepCard },
  props: {
    steps: {
      type: Array,
      required: true
    }
  }
}
</script>
