export const REGISTRATION_STEPS = [
  {
    title: 'Регистрация',
    description: 'После регистрации, подтвердите аккаунт на указанной Вами эл. почте.'
  },
  {
    title: 'Загрузка\u00A0документов\u00A0в\u00A0профиль',
    description: 'Перейдите в личный профиль и нажмите на кнопку редактирования.'
  },
  {
    title: '',
    description: 'Добавьте все требуемые документы и сохраните изменения.'
  },
  {
    title: 'Отправка заявки',
    description: 'Нажмите на "Получить\u00A0аккредитацию" в личном профиле.'
  },
  {
    title: 'Процесс аккредитации',
    description: 'Дождитесь уведомления об аккредитации на эл. почте или в личном  кабинете.'
  },
].map((item, i) => ({
  id: i + 1,
  ...item
}))
