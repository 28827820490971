<script setup>
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
import { defineEmits } from 'vue'

const emit = defineEmits(['verify'])

const key = process.env.VUE_APP_CAPTCHA_SITE_KEY

const verify = () => {
  emit('verify')
}

</script>

<template>
  <vue-hcaptcha
    :sitekey="key"
    language="ru"
    @verify="verify"
  ></vue-hcaptcha>
</template>
