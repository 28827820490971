<template>
  <div class="step-card">
    <h4 v-if="hasTitle" class="step-card__title">{{ title }}</h4>

    <div class="step-card__number">
      Шаг {{ number }}
      <VIcon
        v-if="hasIcon"
        icon-name="VectorLong"
        width="35"
        height="26"
      />
    </div>

    <p class="step-card__description">{{ description }}</p>
  </div>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
export default {
  name: 'StepCard',
  components: { VIcon },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    number: {
      type: [String, Number],
      required: false,
      default: 1
    },
    hasIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    hasTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
